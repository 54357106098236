$color-palette: (
  white: (
    base: #ffffff
  ),

  black: (
    light: #263238,
    base: #000000
  ),

  sky: (
    lightest: #F8F9FA,
    lighter: #f9fafb,
    light: #f4f6f8,
    base: #dfe3e8,
    dark: #c4cdd5,
    avatar: #E3F2FD
  ),

  ink: (
    lightest: #919eab,
    lighter: #637381,
    light: #454f5b,
    base: #212b36,
    text: #455A64
  ),

  blue: (
    lighter: #ebf4fa,
    light: #b5ddf2,
    base: #2c97d1,
    dark: #247aa8,
    darker: #0a374e,
    text: #3e4e57
  ),

  green: (
    lighter: #dffde9,
    light: #97e9b0,
    base: #27ae60,
    dark: #008233,
    darker: #173630,
    text: #3e4f43
  ),

  yellow: (
    lighter: #fcf1cd,
    light: #ffea8a,
    base: #eec200,
    dark: #9c6f19,
    darker: #573b00,
    text: #595130
  ),

  orange: (
    lighter: #fcebdb,
    light: #ffc58b,
    base: #f49342,
    dark: #c05717,
    darker: #4a1504,
    text: #594430
  ),

  red: (
    lighter: #fbeae5,
    light: #fead9a,
    base: #ed6347,
    dark: #bf0711,
    darker: #330101,
    text: #583c35
  ),

  purple: (
    lighter: #f6f0fd,
    light: #e3d0ff,
    base: #9c6ade,
    dark: #50248f,
    darker: #230051,
    text: #50495a
  ),

  teal: (
    lighter: #e0f5f5,
    light: #b7ecec,
    base: #47c1bf,
    dark: #00848e,
    darker: #003135,
    text: #405352
  ),

  indigo: (
    lighter: #f4f5fa,
    light: #b3bcf5,
    base: #5c6ac4,
    dark: #202e78,
    darker: #000639,
    text: #3e4155
  )
);
