@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700');
@import "./reset/index";
@import "./base";

html {
  font-family: 'Open Sans', sans-serif;
  color: color(ink);
  font-size: font-size(body);
  line-height: line-height(body);
  background-color: color(sky, lighter);
  overflow-x: hidden;
}

a {
  & {
    color: color(blue);
    text-decoration: none
  }
  &:hover {
    color: color(blue, dark)
  }
}

svg {
  margin: 0 auto;
  width: 500px
}
#beatline {
  stroke-width:2;
  stroke:red;
  filter:url(#glow);
}
#runbeat {
  x: 0;
  animation: heartbeat 7s linear infinite;
}
@keyframes heartbeat {
  from { x: -200; }
  to { x: 600; }
}
